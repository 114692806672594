<template>
  <main>
    <section v-if="isDocument">
      <b-button
        sm
        variant="outline-primary"
        @click.prevent="onFileClick"
        :class="className"
        :style="cssProps"
      >
        {{ fileName }}
      </b-button>
    </section>
    <section v-else-if="isImage">
      <b-img
        :style="cssProps"
        :src="fileUrl"
        :class="className"
        alt="fileName"
      />
    </section>
    <section v-else-if="isAudio">
      <audio :class="className" :style="cssProps" controls>
        <source
          :src="fileUrl"
          :type="fileExt == 'mp3' ? 'audio/mpeg' : 'audio/ogg'"
        />
        Browser kamu tidak support file audio.
      </audio>
    </section>
    <section v-else-if="isVideo">
      <video :class="className" :style="cssProps" controls>
        <source
          :src="fileUrl"
          :type="fileExt == 'mp4' ? 'video/mp4' : 'video/ogg'"
        />
        Browser kamu tidak support file video.
      </video>
    </section>
  </main>
</template>
<script>
import { BButton, BImg } from "bootstrap-vue";
export default {
  props: {
    file_path: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: "",
    },
    cssProps: {
      type: String,
      default: "",
    },
  },
  components: {
    BButton,
    BImg,
  },
  computed: {
    fileName() {
      const arrPath = this.file_path.split("/");
      return arrPath[arrPath.length - 1];
    },
    fileExt() {
      return this.fileName.split(".").pop().toLowerCase();
    },
    fileUrl() {
      return `${this.apiFile}/${this.file_path}`;
    },
    isDocument() {
      return ["pdf", "pdfx", "doc", "docx", "csv", "xls", "xlsx"].includes(
        this.fileExt
      );
    },
    isImage() {
      return ["jpg", "jpeg", "svg", "gif", "png"].includes(this.fileExt);
    },
    isAudio() {
      return ["mp3", "ogg"].includes(this.fileExt);
    },
    isVideo() {
      return ["mp4", "avi"].includes(this.fileExt);
    },
  },
  methods: {
    onFileClick() {
      return this.$emit("onClick", {
        url: this.fileUrl,
        ext: this.fileExt,
      });
    },
  },
};
</script>
