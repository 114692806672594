var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_vm.isDocument ? _c('section', [_c('b-button', {
    class: _vm.className,
    style: _vm.cssProps,
    attrs: {
      "sm": "",
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onFileClick($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.fileName) + " ")])], 1) : _vm.isImage ? _c('section', [_c('b-img', {
    class: _vm.className,
    style: _vm.cssProps,
    attrs: {
      "src": _vm.fileUrl,
      "alt": "fileName"
    }
  })], 1) : _vm.isAudio ? _c('section', [_c('audio', {
    class: _vm.className,
    style: _vm.cssProps,
    attrs: {
      "controls": ""
    }
  }, [_c('source', {
    attrs: {
      "src": _vm.fileUrl,
      "type": _vm.fileExt == 'mp3' ? 'audio/mpeg' : 'audio/ogg'
    }
  }), _vm._v(" Browser kamu tidak support file audio. ")])]) : _vm.isVideo ? _c('section', [_c('video', {
    class: _vm.className,
    style: _vm.cssProps,
    attrs: {
      "controls": ""
    }
  }, [_c('source', {
    attrs: {
      "src": _vm.fileUrl,
      "type": _vm.fileExt == 'mp4' ? 'video/mp4' : 'video/ogg'
    }
  }), _vm._v(" Browser kamu tidak support file video. ")])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }